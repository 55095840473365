import { Dayjs } from 'dayjs'
import { DateRange, NumberRange, } from '../common'
import { api, PagingSearchParaVo, CommonListVo, SearchParaPropertis, converter, } from '../api'
const dayjs = require('dayjs')
// アクセスURI
const uri = '/api/billingDetail'
// ページ毎行数
const rowCount = 10
// ソート順項目定義、repositoryと一致
const orderByColumns = [
  // 会社コード
  "companyId",
  // 取引年月
  "acquisitionDate",
  // 支払日
  "paymentDate",
  // 支払合計
  "purchaseAmount",
  // 仕入先コード
  "supplierCode",
  // 仕入先名称
  "supplierName",
]

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
  // 仕入先コード
  suppliers: 'array',
  // 受注者名称
  supplierName: 'string',
  // 取引年月
  acquisitionDate: 'DateRange',
  // 支払日
  paymentDate: 'DateRange',
  // 支払合計
  purchaseAmount: 'NumberRange',
}

// 仕入明細一覧VO
interface BillingDetailListVo {
  // 会社コード
  companyId: string,
  // 年月
  acquisitionDate: number,
  // 支払日
  paymentDate: number,
  // 仕入先コード
  supplierCode: string,
  // 仕入先名称
  supplierName: string,
  // 都度支払フラグ
  paymentUponEachUseFlag: string,
  // 仕入金額
  purchaseAmount: number,
}

// 仕入明細詳細VO
interface BillingDetailDetailVo {
  // ID
  id: number;
  // 会社コード
  companyId: string,
  // 年月
  acquisitionDate: number,
  // 支払日
  paymentDate: number,
  // 仕入先コード
  supplierCode: string,
  // 仕入先名称
  supplierName: string,
  // 転記日付
  transcriptionDate: string,
  // 納品伝票番号
  deliverySlipNumber: string,
  // 購買発注NO
  purchaseOrderNo: string,
  // 購買発注伝票明細
  purchaseDetailNo: string,
  // 入出庫/請求伝票
  dispatchSlipNo: string,
  // 入出庫/請求伝票明細
  dispatchSlipDetailNo: string,
  // 会計伝票
  accountingSlipNo: string,
  // 会計伝票明細
  accountingSlipDetailNo: string,
  // 品目コード
  itemCode: string,
  // 品目テキスト
  itemText: string,
  // 仕入数量
  incorporationQuantity: number,
  // 単位
  unit: string,
  // 仕入単価
  purchaseUnitPrice: string,
  // 仕入金額
  purchaseAmount: string,
  // 通貨
  currencyCode: string,
  // 税率
  purchaseTax: string,
}

//仕入明細合計DTO
export interface BillingDetailDetailAmountVo {
  // ID
  id: number;
  // 会社コード
  companyId: string,
  // 年月
  acquisitionDate: number,
  // 仕入先コード
  supplierCode: string,
  // 品目テキスト
  itemText: string,
  // 仕入金額
  purchaseAmount: number,
  // 通貨
  currencyCode: string,
}

// 支払検索条件Dto
export interface BillingDetailSearchDto extends PagingSearchParaVo {
  // 仕入先コード
  suppliers: string[],
  // 受注者名称
  supplierName?: string,
  // 取引年月
  acquisitionDate: DateRange,
  // 支払日
  paymentDate: DateRange,
  // 支払合計
  purchaseAmount: NumberRange
}
//仕入明細一覧DTO
export interface BillingDetailListDto {
  // 会社コード
  companyId: string,
  // 支払日
  paymentDate: Dayjs,
  // 年月
  acquisitionDate: Dayjs,
  // 仕入先コード
  supplierCode: string,
  // 仕入先名称
  supplierName: string,
  // 都度支払フラグ
  paymentUponEachUseFlag: string,
  // 支払合計情報
  billingDetailDetailAmounts: BillingDetailDetailAmountDto[],
  // 仕入金額
  purchaseAmount: number,
  
}
//仕入明細詳細DTO
export interface BillingDetailDetailDto {
  // ID
  id: number;
  // 会社コード
  companyId: string,
  // 年月
  acquisitionDate: Dayjs,
  // 支払日
  paymentDate: Dayjs,
  // 仕入先コード
  supplierCode: string,
  // 仕入先名称
  supplierName: string,
  // 転記日付
  transcriptionDate: string,
  // 納品伝票番号
  deliverySlipNumber: string,
  // 購買発注NO
  purchaseOrderNo: string,
  // 購買発注伝票明細
  purchaseDetailNo: string,
  // 入出庫/請求伝票
  dispatchSlipNo: string,
  // 入出庫/請求伝票明細
  dispatchSlipDetailNo: string,
  // 会計伝票
  accountingSlipNo: string,
  // 会計伝票明細
  accountingSlipDetailNo: string,
  // 品目コード
  itemCode: string,
  // 品目テキスト
  itemText: string,
  // 仕入数量
  incorporationQuantity: number,
  // 単位
  unit: string,
  // 仕入単価
  purchaseUnitPrice: string,
  // 仕入金額
  purchaseAmount: string,
  // 通貨
  currencyCode: string,
  // 税率
  purchaseTax: string,

}
//仕入明細合計DTO
export interface BillingDetailDetailAmountDto {
  // ID
  id: number;
  // 会社コード
  companyId: string,
  // 年月
  acquisitionDate: Dayjs,
  // 仕入先コード
  supplierCode: string,
  // 品目テキスト
  itemText: string,
  // 仕入金額
  purchaseAmount: number,
  // 通貨
  currencyCode: string,
}



const listVo2Dto = (vo: BillingDetailListVo): BillingDetailListDto => {
  return {
    ...vo,
    acquisitionDate: dayjs(vo.acquisitionDate),
    paymentDate: dayjs(vo.paymentDate),
  } as BillingDetailListDto
}

const detailVo2Dto = (vo: BillingDetailDetailVo): BillingDetailDetailDto => {
  return {
    ...vo,
    acquisitionDate: dayjs(vo.acquisitionDate),
    paymentDate: dayjs(vo.paymentDate)
  } as BillingDetailDetailDto
}

const detailAmountVo2Dto = (vo: BillingDetailDetailAmountVo): BillingDetailDetailAmountDto => {
  return {
    ...vo,
    acquisitionDate: dayjs(vo.acquisitionDate),
  } as BillingDetailDetailAmountDto
}
//仕入明細一覧情報取得
export const get = (query: string): Promise<[number, BillingDetailListDto[]]> => {
  if (!query || query.length === 0) {
    query = `?rowCount=${rowCount}`
  } else {
    query += `&rowCount=${rowCount}`
  }
  return api.get(`${uri}${query}`)
    .then((result: any) => {
      const data = result as CommonListVo<BillingDetailListVo>
      return [data.total, data.vos.map(listVo2Dto)]
    })
}
//仕入明細詳細情報取得
export const getDetail = (urlCompanyID: string, urlSupplier: string, urlAcquisitionDate: string, urlPaymentDate: string): Promise<BillingDetailDetailDto[]> => {
  return api.get(`${uri}/detail?companyId=${urlCompanyID}&supplier=${urlSupplier}&acquisitionDate=${urlAcquisitionDate}&paymentDate=${urlPaymentDate}`)
    .then((result: any) => {
      const dataList = result as BillingDetailDetailVo[]
      const dataDto: BillingDetailDetailDto[] = []
      dataList.forEach(data => {
        dataDto.push(detailVo2Dto(data))
      })
      return dataDto
    })
}

//仕入明細詳細情報取得
export const getDetailAmount = (urlCompanyID: string, urlSupplier: string, urlAcquisitionDate: string, urlPaymentDate: string): Promise<BillingDetailDetailAmountDto[]> => {
  return api.get(`${uri}/detail/amount?companyId=${urlCompanyID}&supplier=${urlSupplier}&acquisitionDate=${urlAcquisitionDate}&paymentDate=${urlPaymentDate}`)
    .then((result: any) => {
      const dataList = result as BillingDetailDetailAmountVo[]
      const dataDto: BillingDetailDetailAmountDto[] = []
      dataList.forEach(data => {
        dataDto.push(detailAmountVo2Dto(data))
      })
      return dataDto
    })
}

// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): BillingDetailSearchDto => {
  return converter.search2Dto(properties, orderByColumns, query) as BillingDetailSearchDto
}

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: BillingDetailSearchDto): string => {
  return converter.dto2search(properties, orderByColumns, researchDto)
}