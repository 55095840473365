import dayjs, { Dayjs } from 'dayjs';
import { DateRange, NumberRange, } from '../common';
import { api, converter, CommonListVo, PagingSearchParaVo, SearchParaPropertis } from '../api'

// アクセスURI
const uri = '/api/orderresponses';

//1. 検索画面
// ソート順項目定義、repositoryと一致
const orderByColumns = [
  'orderNo', "buyer", "buyerName", 'supplier', 'supplierName',
  'item', 'itemName', 'infoType', 'orderDate', 'iniDeliveryDate',
  'productionPlanDate', 'purchasingManagerName', 'locationCd', 'finishFlag', 'unitPrice',
  'amount'
];

// 検索条件Dtoの項目一覧
const properties: SearchParaPropertis = {
  // 注文番号
  orderNo: 'string',
  // 注文日
  orderDate: 'DateRange',
  // 受注者コード
  suppliers: 'array',
  // 受注者名称
  supplierName: 'string',
  // 品目コード
  items: 'array',
  // 品名
  itemName: 'string',
  // 情種類
  infoType: 'string',
  // 納品予定日
  iniDeliveryDate: 'DateRange',
  // 再計画日
  productionPlanDate: 'DateRange',
  // 購買担当
  purchasingManagerName: 'string',
  // 保管場所
  locationCd: 'string',
  // 納入完了フラグ
  finishFlag: 'string',
  //単価
  unitPrice: 'NumberRange',
  // 注文金額
  amount: 'NumberRange',
  // 注文残金額
  balanceAmount: 'NumberRange',
};

// 納期回答検索条件Dto
export interface OrderResponseSearchDto extends PagingSearchParaVo {
  // 注文番号
  orderNo?: string;
  // 注文日
  orderDate: DateRange;
  // 受注者コード
  suppliers: string[];
  // 受注者名称
  supplierName?: string;
  // 品目コード
  items: string[];
  // 品名
  itemName?: string;
  //情報種類
  infoType: string;
  // 納品予定日
  iniDeliveryDate: DateRange;
  // 再計画日
  productionPlanDate: DateRange;
  // 購買担当
  purchasingManagerName?: string;
  // 保管場所
  locationCd?: string;
  // 納入完了フラグ
  finishFlag?: string;
  // 単価
  unitPrice: NumberRange;
  // 注文金額
  amount: NumberRange;
  // 注文残金額
  balanceAmount: NumberRange;
}
// searchから検索条件Dtoへ変換する
export const search2Dto = (query?: string): OrderResponseSearchDto => {
  return converter.search2Dto(properties, orderByColumns, query) as OrderResponseSearchDto;
};

// 検索条件Dtoからsearchへ変換する
export const dto2search = (researchDto: OrderResponseSearchDto): string => {
  return converter.dto2search(properties, orderByColumns, researchDto);
};

//2.一覧／ 詳細画面
// 発注一覧／ 詳細情報のVOクラス
interface OrderVo {
  // ID
  id: number;
  // 注文番号
  orderNo: string;
  // 明細番号
  detailNo: number;
  // 品番
  item?: string;
  // 品名
  itemName?: string;
  // 注文日
  orderDate: number;
  // 発注者コード
  buyer?: string;
  // 発注者名称
  buyerName?: string;
  // 受注者コード
  supplier?: string;
  // 受注者名称
  supplierName?: string;
  // 購買担当
  purchasingManagerName?: string
  // 初期納期
  iniDeliveryDate: number;
  // 再日程日
  productionPlanDate?: number;
  // 通貨コード
  currencyCode?: string;
  // 納入指示有無区分
  deliveryFlag?: string;
  // 保管場所コード
  locationCd?: string;
  // 保管場所名称
  locationName?: string;
  // 品質検査
  qualityCheckFlag?: string;
  // 安全区分
  securityType?: string;
  // テキスト短
  text?: string;
  // 情報種コード
  infoType?: string;
  // 情報種名称
  infoTypeName?: string;
  // 単価
  unitPrice?: number;
  // 数量
  quantity?: number;
  //入庫数量
  acceptanceQuantity?: number;
  //出荷済数量
  deliveryQuantity?: number;
  // 単位
  unit?: string;
  // 金額
  amount?: number;
  // 備考
  remark?: string;
  // 既読区分
  unReadFlag?: boolean;
  // 納入完了フラグ
  finishFlag?: string;
  //納品指示
  devInstrucstions: DevInstrucstionVo[];
  //納品回答
  orderResponses: OrderResponseVo[];
  // 出荷情報テーブル
  deliveryDetails: DeliveryInfoVo[];
}
// 発注一覧／ 詳細情報のVOクラス
export interface OrderListRecordDto {
  // ID
  id: number;
  // 注文番号
  orderNo: string;
  // 明細番号
  detailNo: number;
  // 品番
  item?: string;
  // 品名
  itemName?: string;
  // 注文日
  orderDate: Dayjs | null;
  // 発注者コード
  buyer?: string;
  // 発注者名称
  buyerName?: string;
  // 受注者コード
  supplier?: string;
  // 受注者名称
  supplierName?: string;
  // 購買担当
  purchasingManagerName?: string;
  // 初期納期
  iniDeliveryDate: Dayjs | null;
  // 再日程日
  productionPlanDate?: Dayjs | null;
  // 通貨コード
  currencyCode?: string;
  // 納入指示有無区分
  deliveryFlag?: string;
  // 保管場所コード
  locationCd?: string;
  // 保管場所名称
  locationName?: string;
  // 品質検査
  qualityCheckFlag?: string;
  // 安全区分
  securityType?: string;
  // テキスト短
  text?: string;
  // 情報種コード
  infoType?: string;
  // 情報種名称
  infoTypeName?: string;
  // 単価
  unitPrice?: number;
  // 数量
  quantity?: number;
  //入庫数量
  acceptanceQuantity?: number;
  //出荷済数量
  deliveryQuantity?: number;
  // 単位
  unit?: string;
  // 金額
  amount?: number;
  // 備考
  remark?: string;
  // 既読区分
  unReadFlag?: boolean;
  // 納入完了フラグ
  finishFlag?: string;
  //納入指示
  devInstrucstions: DevInstrucstionRecordDto[];
  //納入回答
  orderResponses: OrderResponseRecordDto[];
  // 出荷情報テーブル
  deliveryDetails: DeliveryInfoRecordDto[];
}
// VOからListRecordDtoを変換する
const vo2DeliveryInfoRecordDto = (vo: DeliveryInfoVo): DeliveryInfoRecordDto => {
  return {
    type: 'DeliveryInfo',
    ...vo,
    shipDate: vo.shipDate !== 0? dayjs(vo.shipDate):undefined,
    estimatedTimeArriva: dayjs(vo.estimatedTimeArriva),
  } as DeliveryInfoRecordDto;
};
// VOからListRecordDtoを変換する
const vo2OrderResponseRecordDto = (vo: OrderResponseVo): OrderResponseRecordDto => {
  return {
    type: 'OrderResponse',
    ...vo,
    deliveryPlanDate: dayjs(vo.deliveryPlanDate),
  };
};
// VOからListRecordDtoを変換する
const vo2DevInstrucstionsRecordDto = (vo: DevInstrucstionVo): DevInstrucstionRecordDto => {
  return {
    type: 'DevInstrucstion',
    ...vo,
    devInstrucstionDate: dayjs(vo.devInstrucstionDate),
  };
};
// VOからListRecordDtoを変換する
const vo2ListRecordDto = (vo: OrderVo): OrderListRecordDto => {
  return {
    ...vo,
    orderDate: dayjs(vo.orderDate),
    iniDeliveryDate: dayjs(vo.iniDeliveryDate),
    productionPlanDate: dayjs(vo.productionPlanDate),
    orderResponses: !!vo.orderResponses ? vo.orderResponses.map(vo2OrderResponseRecordDto) : [],
    deliveryDetails: !!vo.deliveryDetails ? vo.deliveryDetails.map(vo2DeliveryInfoRecordDto) : [],
    devInstrucstions: !!vo.devInstrucstions ? vo.devInstrucstions.map(vo2DevInstrucstionsRecordDto) : [],
  };
};

// 一覧取得
export const get = (query: string): Promise<[number, OrderListRecordDto[]]> => {

  return api.get(`${uri}${query}`)
    .then((result: any) => {
      const data = result as CommonListVo<OrderVo>;
      return [data.total, data.vos.map(vo2ListRecordDto)];
    });
};
//詳細情報取得処理
export const getById = (urlId: string): Promise<OrderListRecordDto> => {

  return api.get(`${uri}/${urlId}`)
    .then((result: any) => {
      const data = result as OrderVo;
      return vo2ListRecordDto(data);
    });
};

//3.納品指示
// 納品指示のVOクラス
interface DevInstrucstionVo {
  // ID
  id: number;
  // 指示納品日
  devInstrucstionDate: number;
  // 指示納品数量
  instrucstionQuantity: number;
  // バージョン
  version?: number;
}

//4.納品回答
// 納期回答のVOクラス
interface OrderResponseVo {
  // ID
  id: number;
  // 回答納期
  deliveryPlanDate: number;
  // 数量
  quantity?: number;
  // バージョン
  version?: number;
  
  detailNo?:number;
  // 削除フラグ
  delFlag: string;
}

type EditType = 'OrderResponse' | 'DeliveryInfo' | 'DevInstrucstion';

export interface DetailRecordDto {
  // ID
  id: number;
  // 
  type: EditType;
  // バージョン
  version?: number;
}

// 納期指示のVOクラス
export interface DevInstrucstionRecordDto extends DetailRecordDto {
  // ID
  id: number;
  // 指示納品日
  devInstrucstionDate: Dayjs | null;
  // 指示納品数量
  instrucstionQuantity: number;

  message?: string;
}

// 納期回答のVOクラス
export interface OrderResponseRecordDto extends DetailRecordDto {
  // ID
  id: number;
  // 回答納期
  deliveryPlanDate: Dayjs | null;
  // 数量
  quantity?: number;

  message?: string;

  detailNo?:number;
  // 削除フラグ
  delFlag: string;
}

// 納期回答履歴のVOクラス
interface OrderResponseHistoryVo {
  // ID
  id: number;
  // detailNo
  detailNo: number;
  // 回答納期
  preDeliveryPlanDate?: number;
  // 数量
  preQuantity?: number;
  // 回答納期
  deliveryPlanDate?: number;
  // 数量
  quantity?: number;
  // 訂正コード
  stateCode?: string;
  // 訂正ユーザ
  userId?: string;
  // 訂正ユーザ名
  userName?: string;
  // 訂正日時
  userModifiedDate?: number;
}

//5.納品回答履歴

// 納期回答のVOクラス
export interface OrderResponseHistoryDto {
  // ID
  id: number;
  // detailNo
  detailNo: number;
  // 回答納期
  preDeliveryPlanDate: Dayjs | null;
  // 数量
  preQuantity?: number;
  // 回答納期
  deliveryPlanDate: Dayjs | null;
  // 数量
  quantity?: number;
  // 訂正コード
  stateCode?: string;
  // 訂正ユーザ
  userId?: string;
  // 訂正ユーザ名
  userName?: string;
  // 訂正日時
  userModifiedDate: Dayjs | null;
}

// VOからHistoryDtoを変換する
const vo2OrderResponseHistoryDto = (vo: OrderResponseHistoryVo): OrderResponseHistoryDto => {
  return {
    ...vo,
    deliveryPlanDate: dayjs(vo.deliveryPlanDate),
    preDeliveryPlanDate: dayjs(vo.preDeliveryPlanDate),
    userModifiedDate: dayjs(vo.userModifiedDate),
  };
};
//納品回答履歴情報取得する
export const getOrderResponseHistoryById = (detailId: number): Promise<OrderResponseHistoryDto[]> => {

  return api.get(`${uri}/${detailId}/orderresponsehistory`)
    .then((result: any) => {
      const data = result as OrderResponseHistoryVo[];

      return data.map(vo2OrderResponseHistoryDto);
    });
};

//6．出荷情報
// 出荷情報のVOクラス
interface DeliveryInfoVo {
  // ID
  id: number;
  // 出荷書ID
  deliveryId?: number;
  // 明細番号
  detailNo?: number;
  // 着荷予定日
  estimatedTimeArriva: number;
  // 数量
  quantity?: number;
  // 出荷日
  shipDate?: number;
  // 備考
  remarks?: string;
  // バージョン
  version?: number;
  // 削除フラグ
  delFlag: string;
}
// 出荷情報のDTOクラス
export interface DeliveryInfoRecordDto extends DetailRecordDto {
  // ID
  id: number;
  // 出荷書ID
  deliveryId?: number;
  // 明細番号
  detailNo?: number;
  // 着荷予定日
  estimatedTimeArriva: Dayjs | null;
  // 数量
  quantity?: number;
  // 出荷日
  shipDate?: Dayjs | null;
  // 備考
  remarks?: string;
  // 削除フラグ
  delFlag: string;
}

//7.出荷情報履歴
// 出荷情報履歴のVOクラス
interface DeliveryInfoHistoryVo {
  // ID
  id: number;
  // 出荷書ID
  deliveryId?: number;
  // 明細番号
  detailNo?: number;
  // 着荷予定日
  preEstimatedTimeArriva?: number;
  // 数量
  preQuantity?: number;
  // 出荷日
  preShipDate?: number;
  // 備考
  preRemarks?: string;
  // 着荷予定日
  estimatedTimeArriva?: number;
  // 数量
  quantity?: number;
  // 出荷日
  shipDate?: number;
  // 備考
  remarks?: string;
  // 訂正コード
  stateCode?: string;
  // 訂正ユーザ
  userId?: string;
  // 訂正ユーザ名
  userName?: string;
  // 訂正日時
  userModifiedDate?: number;
}
// 出荷情報履歴のVOクラス
export interface DeliveryInfoHistoryDto {
  // ID
  id: number;
  // 明細番号
  detailNo?: number;
  // 着荷予定日
  preEstimatedTimeArriva: Dayjs | null;
  // 数量
  preQuantity?: number;
  // 出荷日
  preShipDate?: Dayjs | null;
  // 備考
  preRemarks?: string;
  // 着荷予定日
  estimatedTimeArriva: Dayjs | null;
  // 数量
  quantity?: number;
  // 出荷日
  shipDate?: Dayjs | null;
  // 備考
  remarks?: string;
  // 訂正コード
  stateCode?: string;
  // 訂正ユーザ
  userId?: string;
  // 訂正ユーザ名
  userName?: string;
  // 訂正日時
  userModifiedDate: Dayjs | null;
}
// 出荷履歴情報VOからHistoryDtoを変換する
const vo2DeliveryInfoHistoryDto = (vo: DeliveryInfoHistoryVo): DeliveryInfoHistoryDto => {
  return {
    ...vo,
    estimatedTimeArriva: dayjs(vo.estimatedTimeArriva),
    preEstimatedTimeArriva: dayjs(vo.preEstimatedTimeArriva),
    userModifiedDate: dayjs(vo.userModifiedDate),
    shipDate: vo.shipDate !== 0?dayjs(vo.shipDate):undefined,
    preShipDate: vo.preShipDate !== 0?dayjs(vo.preShipDate):undefined
  };
};
//出荷履歴情報を取得する
export const getDeliveryInfoHistoryById = (detailId: number): Promise<DeliveryInfoHistoryDto[]> => {

  return api.get(`${uri}/${detailId}/deliverydetailhistory`)
    .then((result: any) => {
      const data = result as DeliveryInfoHistoryVo[];

      return data.map(vo2DeliveryInfoHistoryDto);
    });
};

//納品回答／出荷情報削除処理
export const removeById = (orderId: number, dto: DetailRecordDto): Promise<any> => {

  return api.removeById(`${uri}/${orderId}/${dto.type.toLocaleLowerCase()}/`, dto.id, { id: dto.id, version: dto.version });
};

//納品回答／出荷情報編集処理
export const merge = (orderId: number, dto: DetailRecordDto): Promise<any> => {

  switch (dto.type) {
    case 'OrderResponse':

      if (!!dto.id && dto.id > 0) {
        return api.merge(`${uri}/${orderId}/orderresponse/`, dto.id, orderResponseRecordDto2Vo(dto as OrderResponseRecordDto));
      } else {
        return api.persist(`${uri}/${orderId}/orderresponse`, orderResponseRecordDto2Vo(dto as OrderResponseRecordDto));
      }
    case 'DeliveryInfo':

      if (!!dto.id && dto.id > 0) {
        return api.merge(`${uri}/${orderId}/deliveryinfo/`, dto.id, deliveryInfoRecordDto2Vo(dto as DeliveryInfoRecordDto));
      } else {
        return api.persist(`${uri}/${orderId}/deliveryinfo`, deliveryInfoRecordDto2Vo(dto as DeliveryInfoRecordDto));
      }
    case 'DevInstrucstion':

      if (!!dto.id && dto.id > 0) {
        return api.merge(`${uri}/${orderId}/devinstrucstion/`, dto.id, devInstrucstionRecordDto2Vo(dto as DevInstrucstionRecordDto));
      } else {
        return api.persist(`${uri}/${orderId}/devinstrucstion`, devInstrucstionRecordDto2Vo(dto as DevInstrucstionRecordDto));
      }
    default:
      throw dto.type;
  }
};

//8.Up load処理
//納入回答： ListRecordDtoからVOを変換する
const orderResponseRecordDto2Vo = (dto: OrderResponseRecordDto): OrderResponseVo => {
  return {
    ...dto,
    deliveryPlanDate: !!dto.deliveryPlanDate ? dto.deliveryPlanDate.valueOf() : 0,
  };
};

//出荷情報： ListRecordDtoからVOを変換する
const deliveryInfoRecordDto2Vo = (dto: DeliveryInfoRecordDto): DeliveryInfoVo => {
  return {
    ...dto,
    estimatedTimeArriva: !!dto.estimatedTimeArriva ? dto.estimatedTimeArriva.valueOf() : 0,
    shipDate: !!dto.shipDate ? dto.shipDate.valueOf() : 0
  };
};
//納品指示： ListRecordDtoからVOを変換する
const devInstrucstionRecordDto2Vo = (dto: DevInstrucstionRecordDto): DevInstrucstionVo => {
  return {
    ...dto,
    devInstrucstionDate: !!dto.devInstrucstionDate ? dto.devInstrucstionDate.valueOf() : 0,
  };
};
// ListRecordDtoからVOを変換する
const listRecordDto2Vo = (dto: OrderListRecordDto): OrderVo => {
  return {
    ...dto,
    orderDate: 0,
    iniDeliveryDate: 0,
    productionPlanDate: 0,
    orderResponses: dto.orderResponses.map(orderResponseRecordDto2Vo),
    deliveryDetails: dto.deliveryDetails.map(deliveryInfoRecordDto2Vo),
    devInstrucstions: dto.devInstrucstions.map(devInstrucstionRecordDto2Vo),
  };
};

export const fillOrderDetails = (dtos: OrderListRecordDto[]): Promise<any> => {
  const vos: OrderVo[] = dtos.map(listRecordDto2Vo);
  return api.getByData(`${uri}/upload`, vos)
    .then((result: any) => {
      const data = result as OrderVo[];

      return data.map(vo2ListRecordDto);
    });
}
export const uploadDevInstrucstions = (dtos: OrderListRecordDto[]): Promise<any> => {
  const vos: OrderVo[] = dtos.map(listRecordDto2Vo);
  console.log(vos);
  return api.upload(`${uri}/uploaddevinstrucstions`, vos);
}

export const uploadOrderResponses = (dtos: OrderListRecordDto[]): Promise<any> => {
  const vos: OrderVo[] = dtos.map(listRecordDto2Vo);
  console.log(vos);
  return api.upload(`${uri}/uploadorderresponses`, vos);
}

export const uploadDeliveryInfos = (dtos: OrderListRecordDto[]): Promise<any> => {
  const vos: OrderVo[] = dtos.map(listRecordDto2Vo);
  return api.upload(`${uri}/uploaddeliverydetails`, vos);
}

export const readAll = (): Promise<void> => {
  return api.send(`${uri}/readall`);
}

